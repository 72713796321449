/* eslint react/no-unknown-property: "off" */
import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { Auth } from "aws-amplify"

import {
  clearLocalStorage,
  ROUTES,
  captureException,
  SENTRY_ACTIONS,
  isBrowser,
} from "@lesmills/gatsby-theme-common"

type Props = {|
  pageContext: Object,
|}

const PublicPageRedirection = ({ pageContext }: Props) => {
  useEffect(() => {
    if (pageContext.signOut) {
      clearLocalStorage()

      try {
        Auth.signOut()
        // Sign out Vimeo page
        if (isBrowser && window.location) {
          window.location.href = ROUTES().VIMEO_SIGN_OUT
        }
      } catch (err) {
        // Send error to sentry in
        captureException({
          action: SENTRY_ACTIONS.SIGN_OUT,
          ...err,
        })
      }
    }
  })

  return (
    <Helmet>
      {!pageContext.signOut && (
        <meta http-equiv="refresh" content={`0;url=${pageContext.redirect}`} />
      )}
    </Helmet>
  )
}

export default PublicPageRedirection
